import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { Router } from "@angular/router";
import { DataService } from "../../../../data.service";
import { BaseUrlService } from "../../../../shared/services/baseurl.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-matchmodal",
  templateUrl: "./matchmodal.component.html",
  styleUrls: ["./matchmodal.component.scss"],
})
export class MatchmodalComponent {
  items: any[];
  baseURL: string;
  responseReceived:boolean=false;
  async getItems() {
    const host = "http://localhost:5000";
    const response = await fetch(`${this.baseURL}/api/series/getsereis`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    this.items = json;
    // console.log(json);
  }
  receivedData: any;
  public matchForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    public router: Router,
    public modalRef2: MdbModalRef<MatchmodalComponent>,
    private dataService: DataService,
    private baseUrlService: BaseUrlService
  ) {
    this.baseURL = baseUrlService.getBaseUrl();
    this.receivedData = this.dataService.getData();
    // console.log(this.receivedData);
    this.matchForm = this.fb.group({
      name: [this.receivedData.name, Validators.required],
      match: [this.receivedData.matchType, Validators.required],
      teamA: [this.receivedData.teamA, Validators.required],
      teamB: [this.receivedData.teamB, Validators.required],
      venue: [this.receivedData.venue, Validators.required],
      status: [this.receivedData.status, Validators.required],
      series: [this.receivedData.series, Validators.required],
      datetime: [this.receivedData.datetime, Validators.required],
      cusmsg: [
        this.receivedData.msg ? this.receivedData.msg : "",
        Validators.required,
      ],
      switchValue: [
        this.receivedData.switchValue ? this.receivedData.switchValue : false,
        Validators.required,
      ],
      // overI: ["", Validators.required],
      // wicketS: ["", Validators.required],
      // wicketI: ["", Validators.required],
      // inningsI: ["", Validators.required],
      // inningsS: ["", Validators.required],
    });
  }
  sendId() {
    const idToSend = "done";
    this.dataService.setData(idToSend);
  }
  ngOnInit() {
    this.getItems(); // Call the method to fetch the dropdown items
  }
  async updateMatch() {
    this.responseReceived=true;
    // console.log(this.matchForm.value["switchValue"]);
    // console.log(this.matchForm.value["cusmsg"]);
    const host = "http://localhost:5000";
    const response = await fetch(
      `${this.baseURL}/api/match/updatematch/${this.receivedData._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          name: this.matchForm.value["name"],
          matchType: this.matchForm.value["match"],
          teamA: this.matchForm.value["t20"],
          teamB: this.matchForm.value["odi"],
          status: this.matchForm.value["status"],
          series: this.matchForm.value["series"],
          venue: this.matchForm.value["venue"],
          datetime: this.matchForm.value["datetime"],
          msg: this.matchForm.value["cusmsg"],
          switchValue: this.matchForm.value["switchValue"],
          // edate: this.matchForm.value["edate"],
        }),
      }
    );
    const json = await response.json();
    // console.log(json);
    this.sendId();
    this.basicAlert();
    this.modalRef2.close();
    this.ngOnInit();
    if(json){
      this.responseReceived = false;
    }
    else {
      setTimeout(()=>this.responseReceived = false, 4000);
    }
  }
  basicAlert() {
    Swal.fire("Match has been updated");
  }
  
}
