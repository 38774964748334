import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  // const name: any = JSON.parse(localStorage.getItem("user"))[0].email;
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";

  constructor(public router: Router) {
    if (JSON.parse(localStorage.getItem("user"))) {
    } else {
    }
  }

  ngOnInit() {}

  logoutFunc() {
    console.log(this.userName);
    console.log(localStorage.getItem("token"));
    // console.log(this.name);
    console.log(localStorage.getItem("user"));
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    console.log(localStorage.getItem("token"));
    this.router.navigateByUrl("auth/login");
  }
}
