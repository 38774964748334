import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class BaseUrlService {
  // private baseUrl: string = "http://142.93.214.130:5000"; // Modify the port if needed
  // private baseUrl: string = "http://localhost:5000"; // Modify the port if needed
  private baseUrl: string = "https://api.cricketgyan.com"; // Modify the port if needed

  getBaseUrl(): string {
    return this.baseUrl;
  }
}
