import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class DatawResService {
  private responseSubject = new Subject<boolean>();
  public response$ = this.responseSubject.asObservable();

  private sharedData: any;
  private matchid: string;
  wickets: number;

  setData2(data: any, matchid: string) {
    this.sharedData = data;
    this.matchid = matchid;
  }
  wicketCount(data: any, matchid: string, wickets:number){
    this.sharedData = data;
    this.matchid = matchid;
    this.wickets = wickets;
  }
  getwicketCount() {
    return { data: this.sharedData, id: this.matchid ,wickets:this.wickets};
  }
  getData2() {
    return { data: this.sharedData, id: this.matchid };
  }

  setData(data: any) {
    this.sharedData = data;
  }

  getData() {
    return this.sharedData;
  }
  receiveModalResponse(isSubmitted: boolean) {
    this.responseSubject.next(isSubmitted);
  }
}
