<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Update Match</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef2.close()"></button>
</div>
<div class="modal-body">
    <form class="theme-form" [formGroup]="matchForm">



        <div class="form-group">
            <label class="col-form-label">Enter Match Name</label>
            <input class="form-control" type="text" required="" placeholder="Match Name" formControlName="name">
        </div>
        <div class="form-group">
            <label class="col-form-label">Match Type</label>
            <select class="form-select" id="inputGroupSelect01" formControlName="match">
                <option disabled selected>Match Type</option>
                <option value="ODI">ODI</option>
                <option value="Test">Test</option>
                <option value="T20">T20</option>
            </select>
            <!-- <input class="form-control" type="text" required="" placeholder="Match Type" formControlName="match"> -->
        </div>
        <div class="form-group">
            <label class="col-form-label">Team A</label>
            <input class="form-control" type="text" required="" placeholder="Team A" formControlName="teamA">
        </div>
        <div class="form-group">
            <label class="col-form-label">Team B</label>
            <input class="form-control" type="text" required="" placeholder="Team B" formControlName="teamB">
        </div>
        <div class="form-group">
            <label class="col-form-label">Venue</label>
            <input class="form-control" type="text" required="" placeholder="Venue" formControlName="venue">
        </div>
        <!-- <div class="form-group row">
            <label class="col-form-label">Status</label>
            <div class="col">

                <input class="form-control col" type="text" required="" placeholder="Runs" formControlName="runS">
            </div>
            <div class="col">

                <input class="form-control col" type="text" required="" placeholder="Over" formControlName="overS">
            </div>
            <div class="col">

                <input class="form-control col" type="text" required="" placeholder="Wicket" formControlName="wicketS">
            </div>
            <div class="col">

                <input class="form-control col" type="text" required="" placeholder="Innings"
                    formControlName="inningsS">
            </div>

        </div> -->
        <div class="form-group">
            <label class="col-form-label">Status</label>
            <select class="form-select" id="inputGroupSelect01" formControlName="status" placeholder="Status">
                <option disabled selected>Status</option>
                <option value="Upcoming">Upcoming</option>
                <option value="Live">Live</option>
                <option value="Finished">Finished</option>
            </select>
        </div>
        <div class="form-group">
            <label class="col-form-label">Series</label>
            <select class="form-select" id="inputGroupSelect01" formControlName="series">
                <option disabled selected>Select Series</option>
                <option *ngFor="let item of items" [value]="item.name">{{ item.name }}</option>
            </select>

        </div>
        <div class="form-group">
            <label class="col-form-label">Date And Time </label>
            <input class="form-control digits" id="example-datetime-local-input" type="datetime-local"
                placeholder="2022-01-19T18:45:00" formControlName="datetime" />
        </div>
        <div class="form-group">
            <label class="col-form-label">Enter Custom Message</label>
            <input class="form-control" type="text" required="" placeholder="Enter Message" formControlName="cusmsg">
            <div class="form-check fs-5 form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                    formControlName="switchValue">
            </div>
        </div>

        <!-- <div class="form-group row">
            <label class="col-form-label">Innings</label>
            <div class="col">

                <input class="form-control col" type="text" required="" placeholder="Runs" formControlName="runI">
            </div>
            <div class="col">

                <input class="form-control col" type="text" required="" placeholder="Over" formControlName="overI">
            </div>
            <div class="col">

                <input class="form-control col" type="text" required="" placeholder="Wicket" formControlName="wicketI">
            </div>
            <div class="col">

                <input class="form-control col" type="text" required="" placeholder="Innings"
                    formControlName="inningsI">
            </div>

        </div> -->
        <br>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="modalRef2.close()">
                Close
            </button>

            <button type="button" (click)="updateMatch()" class="btn btn-primary" [disabled]="responseReceived">Update</button>
        </div>
    </form>
</div>