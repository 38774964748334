import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class ContentGuard implements CanActivate {
  constructor(public router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log("dnojnrjonjornorjn")
    // Guard for user is login or not
    let user = JSON.parse(localStorage.getItem("user"));
    console.log(user)
    if (!user || user === null) {
      this.router.navigate(["/auth/login"]);
      return true;
    } else if (user) {
      if (!Object.keys(user).length) {
        this.router.navigate(["/auth/login"]);
        return true;
      }
    }
    if(user.role==="Content"||user.role==="Admin"){
        console.log("helloContent")
        return true; 
    }
    else{
      this.router.navigate(["/error-page/error-401"]);
      return false;
    }
  }
}
                                                                                                        