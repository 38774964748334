import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { BaseUrlService } from "../../shared/services/baseurl.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false;
  public errorMessage: any;
  baseURL: string;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private baseUrlService: BaseUrlService
  ) {
    this.baseURL = baseUrlService.getBaseUrl();
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }

  ngOnInit() {}

  async login() {
    try {
      const host = "http://localhost:5000";
      const payload = {
        email: this.loginForm.value["email"],
        password: this.loginForm.value["password"],
      };
  
      console.log("Payload:", JSON.stringify(payload)); // Check the payload here
  
      const response = await fetch(`${this.baseURL}/api/auth/login`, {
        method: "POST",
        
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      const json = await response.json();
      if (json.success) {
        let user = {
          email: json.user.email,
          password: json.user.password,
          name: json.user.name,
          role: json.user.role
        };
        console.log("User:", json.user);
        localStorage.setItem("token", json.authtoken);
        localStorage.setItem("user", JSON.stringify(user));
        this.router.navigate(["/dashboard/default"]);
      } else {
        alert("Invalid Credentials");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }
  

  showPassword() {
    this.show = !this.show;
  }
}
